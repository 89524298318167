.App-header {
  background-color: var(--purple);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.Header-Text{
  margin: 1em 0 0 0;
  padding: 0;
  font-size: calc(10px + 2vmin);
  font-weight: 800;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.Header-Text-Black{
    color: var(--black);
    padding: 1em 0 0 0;
    font-size: calc(10px + 2vmin);
    font-weight: 800;
    justify-content: start;
    align-items: flex-start;
    display: flex;
    text-align: start;

}

.Header-Text2{
    color: var(--black);
    font-size: calc(10px + 1.5vmin);
    font-weight: 600;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    text-align: start;
}

.Company-Description-Text {
    font-size: calc(10px + 1.5vmin);
    margin: 0 0 1em 0;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    text-align: start;
    color: #222222;
}


.Company-Name {
  font-size: calc(10px + 4vmin);
  margin: 1em 0px .05em 0px;
  font-weight: 800;
    text-align: start;
    align-items: flex-start;
    display: flex;
}

.Company-Subtitle {
  font-size: calc(10px + 2vmin);
  margin: 0px 0px 1em 0px;
}

.Paper-Container{
    position: relative;
    margin: 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Services-Container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.AboutUs-Container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 2em 0 2em 0;
}

@media screen and (max-width: 768px) {
    .Services-Container {
        flex-direction: column; /* Stacks the children vertically */
    }
}


.Container-White{
    padding: 2em 0 2em 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FFFFFF;
}

.Container-Sepia{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
